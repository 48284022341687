import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { supabase } from '../lib/supabaseClient';
import ReservationModal from './ReservationModal';

const SeatMap = ({ onSeatClick, reservations = {}, seats, activeSeason }) => {
  const [tooltipInfo, setTooltipInfo] = useState(null);
  const [activeSeasonState, setActiveSeasonState] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showOnlyAvailable, setShowOnlyAvailable] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [highlightedSeatIds, setHighlightedSeatIds] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
  const [startDragPosition, setStartDragPosition] = useState({ x: 0, y: 0 });

  const mapRef = useRef(null);
  const MIN_ZOOM = 0.7;
  const MAX_ZOOM = 2;
  const ZOOM_STEP = 0.1;
  const MOBILE_BREAKPOINT = 640;

  const disabledSeats = [
    'B1-12', 'B1-13', 'B1-14', 'B1-15', 'B1-16',
    'B2-12', 'B2-13', 'B2-14', 'B2-15', 'B2-16',
    'B3-12', 'B3-13', 'B3-14', 'B3-15', 'B3-16',
    'C1-1', 'C1-2', 'C1-3', 'C1-4',
    'C2-1', 'C2-2', 'C2-3', 'C2-4',
    'C3-1', 'C3-2', 'C3-3', 'C3-4',
    'B8-7', 'B8-15', 'B8-16', 'B7-16', 'C8-1', 'C8-9', 'C7-1'
  ];

  const createSeatButton = (seatId) => {
    const reservation = reservations[seatId];
    const isDisabled = disabledSeats.includes(seatId);
    const isAvailable = !reservation && !isDisabled;
    const isHighlighted = highlightedSeatIds.includes(seatId);

    const opacity = showOnlyAvailable && !isAvailable ? 'opacity-20' : 'opacity-100';

    const highlight = isHighlighted ? 'ring-4 ring-white ring-opacity-80 scale-110 z-10' : '';

    if (isDisabled) {
      return (
        <div key={seatId} className="relative group">
          <button
            className={`w-8 h-6 sm:w-12 sm:h-8 text-[8px] sm:text-[10px] rounded 
              bg-gray-800 text-gray-800 cursor-default ${opacity}`}
            disabled={true}
          >
            {seatId}
          </button>
        </div>
      );
    }

    return (
      <div key={seatId} className="relative group">
        <button
          onMouseEnter={(e) => {
            setTooltipInfo({
              seatId,
              x: e.clientX,
              y: e.clientY,
              reservation: reservation || null,
              isAvailable: !reservation && !isDisabled
            });
          }}
          onMouseLeave={() => setTooltipInfo(null)}
          className={`w-8 h-6 sm:w-12 sm:h-8 text-[8px] sm:text-[10px] rounded 
            ${getSeatColor(reservation)} 
            transition-all duration-200 whitespace-nowrap overflow-visible ${opacity} ${highlight}
            hover:opacity-80`}
          onClick={() => onSeatClick(seatId)}
        >
          {seatId}
        </button>
      </div>
    );
  };

  const getSeatColor = (reservation) => {
    if (!reservation) return 'bg-green-500';
    switch (reservation.reservation_type) {
      case 'reserved': return 'bg-red-500';
      case 'partner': return 'bg-blue-500';
      case 'guest': return 'bg-yellow-500';
      case 'other': return 'bg-gray-500';
      default: return 'bg-green-500';
    }
  };

  const createBSection = () => {
    const rows = Array.from({ length: 8 }, (_, i) => i + 1);
    const seats = Array.from({ length: 10 }, (_, i) => i + 7);

    return rows.map(row => (
      <div key={`B${row}`} className="flex gap-1">
        {seats.map(seatNum => {
          const seatId = `B${row}-${seatNum}`;
          return createSeatButton(seatId);
        })}
      </div>
    ));
  };

  const createCSection = () => {
    const rows = Array.from({ length: 8 }, (_, i) => i + 1);
    const seats = Array.from({ length: 9 }, (_, i) => i + 1);

    return rows.map(row => (
      <div key={`C${row}`} className="flex gap-1">
        {seats.map(seatNum => {
          const seatId = `C${row}-${seatNum}`;
          return createSeatButton(seatId);
        })}
      </div>
    ));
  };

  const handleSearch = (term) => {
    if (!term.trim()) {
      setHighlightedSeatIds([]);
      return;
    }

    const searchTermLower = term.toLowerCase();
    const matchingSeats = Object.keys(reservations).filter(seatId => {
      const reservation = reservations[seatId];
      if (!reservation) return false;

      const nameMatch = reservation.name?.toLowerCase().includes(searchTermLower);
      const companyMatch = reservation.company?.toLowerCase().includes(searchTermLower);
      return nameMatch || companyMatch;
    });

    setHighlightedSeatIds(matchingSeats);
  };

  const Legend = () => (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-center gap-3 sm:gap-6 p-4 bg-gray-800 rounded-lg border border-gray-700 w-full sm:w-auto">
        <button
          onClick={() => setShowOnlyAvailable(!showOnlyAvailable)}
          className={`px-4 py-2 rounded-lg transition-all duration-200 ${
            showOnlyAvailable
              ? 'bg-green-600 hover:bg-green-700 text-white'
              : 'bg-gray-700 hover:bg-gray-600 text-gray-200'
          }`}
        >
          {showOnlyAvailable ? 'Näytä kaikki paikat' : 'Näytä vain vapaat paikat'}
        </button>

        <div className="flex items-center gap-2">
          <div className="w-4 h-4 bg-green-500 rounded"></div>
          <span className="text-gray-200 text-sm">Vapaa paikka</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-4 h-4 bg-red-500 rounded"></div>
          <span className="text-gray-200 text-sm">Varattu paikka</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-4 h-4 bg-blue-500 rounded"></div>
          <span className="text-gray-200 text-sm">Kumppani</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-4 h-4 bg-yellow-500 rounded"></div>
          <span className="text-gray-200 text-sm">Kausikortti Lippu.fi</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-4 h-4 bg-gray-500 rounded"></div>
          <span className="text-gray-200 text-sm">Muu</span>
        </div>
      </div>
    </div>
  );

  const renderTooltip = () => {
    if (!tooltipInfo) return null;

    // Lasketaan tooltip sijainti hieman oikealle ja ylös hiiren sijainnista
    const xOffset = 20; // pikseliä oikealle
    const yOffset = 10; // pikseliä ylös

    return (
      <div
        style={{
          left: `${tooltipInfo.x + xOffset}px`,
          top: `${tooltipInfo.y - yOffset}px`
        }}
        className={`
          fixed z-50
          px-4 py-3
          bg-gray-900 text-white rounded-lg
          border border-gray-700
          shadow-xl
          animate-fade-in
          backdrop-blur-sm bg-opacity-95
          pointer-events-none
        `}
      >
        <div className="flex flex-col gap-1">
          <div className="font-semibold border-b border-gray-700 pb-1 mb-1">
            Paikka {tooltipInfo.seatId}
          </div>
          {tooltipInfo.reservation ? (
            <>
              <div className="text-sm">
                <span className="text-gray-400">Nimi:</span>{' '}
                <span className="font-medium">{tooltipInfo.reservation.name}</span>
              </div>
              {tooltipInfo.reservation.company && (
                <div className="text-sm">
                  <span className="text-gray-400">Yritys:</span>{' '}
                  <span className="font-medium">{tooltipInfo.reservation.company}</span>
                </div>
              )}
            </>
          ) : tooltipInfo.isAvailable ? (
            <div className="text-sm">
              <span className="text-green-400 font-medium">Vapaa paikka</span>
              <div className="text-gray-400 text-xs mt-1">Klikkaa varataksesi</div>
            </div>
          ) : (
            <div className="text-sm text-gray-400">Paikka ei käytössä</div>
          )}
        </div>
      </div>
    );
  };

  // Päivitetään animaatio uuden sijainnin mukaiseksi
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes fade-in {
        from {
          opacity: 0;
          transform: translate(0, 10px) scale(0.95);
        }
        to {
          opacity: 1;
          transform: translate(0, 0) scale(1);
        }
      }
      .animate-fade-in {
        animation: fade-in 0.2s ease-out forwards;
      }
    `;
    document.head.appendChild(style);
  }, []);

  useEffect(() => {
    const fetchActiveSeason = async () => {
      try {
        const { data, error } = await supabase
          .from('seasons')
          .select('id, name, start_date, end_date')
          .eq('is_active', true)
          .single();

        if (error) throw error;
        setActiveSeasonState(data);
      } catch (err) {
        console.error('Virhe aktiivisen kauden haussa:', err);
      }
    };

    fetchActiveSeason();
  }, []);

  const handleReservationSubmit = async (formData) => {
    if (!activeSeasonState) {
      console.error('Ei aktiivista kautta!');
      return;
    }

    try {
      const reservationData = {
        ...formData,
        season_id: activeSeasonState.id
      };

      console.log('Tallennettava data:', reservationData);

      const { error } = await supabase
        .from('reservations')
        .insert([reservationData])
        .select();

      if (error) {
        throw error;
      }

      setIsModalOpen(false);

    } catch (err) {
      console.error('Varauksen tallennus epäonnistui:', err);
    }
  };

  // Zoom-kontrollit
  const handleZoomIn = () => {
    setZoomLevel(prev => Math.min(prev + ZOOM_STEP, MAX_ZOOM));
  };

  const handleZoomOut = () => {
    setZoomLevel(prev => Math.max(prev - ZOOM_STEP, MIN_ZOOM));
  };

  // Kosketusele-käsittelijät
  const handleTouchStart = (e) => {
    if (e.touches.length === 2) {
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const distance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
      mapRef.current.dataset.initialPinchDistance = distance;
      mapRef.current.dataset.initialZoom = zoomLevel;
    } else if (e.touches.length === 1) {
      setIsDragging(true);
      setStartDragPosition({
        x: e.touches[0].clientX - dragPosition.x,
        y: e.touches[0].clientY - dragPosition.y
      });
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 2) {
      e.preventDefault();
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const distance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );
      const initialDistance = parseFloat(mapRef.current.dataset.initialPinchDistance);
      const initialZoom = parseFloat(mapRef.current.dataset.initialZoom);
      const newZoom = Math.min(
        Math.max(initialZoom * (distance / initialDistance), MIN_ZOOM),
        MAX_ZOOM
      );
      setZoomLevel(newZoom);
    } else if (isDragging && e.touches.length === 1) {
      setDragPosition({
        x: e.touches[0].clientX - startDragPosition.x,
        y: e.touches[0].clientY - startDragPosition.y
      });
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    delete mapRef.current?.dataset.initialPinchDistance;
    delete mapRef.current?.dataset.initialZoom;
  };

  useEffect(() => {
    const setInitialZoom = () => {
      const isMobile = window.innerWidth < MOBILE_BREAKPOINT;
      setZoomLevel(isMobile ? 0.7 : 1);
    };

    setInitialZoom();
    window.addEventListener('resize', setInitialZoom);
    return () => window.removeEventListener('resize', setInitialZoom);
  }, []);

  if (zoomLevel === null) {
    return null;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      {activeSeasonState && (
        <div className="mb-8 bg-gradient-to-r from-gray-800 via-gray-700 to-gray-800 rounded-lg p-4 shadow-lg border border-gray-600">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="flex-shrink-0">
                <div className="h-3 w-3 rounded-full bg-green-500 animate-pulse"></div>
              </div>
              <div>
                <h2 className="text-xl font-semibold text-white">
                  {activeSeasonState.name}
                </h2>
                <p className="text-sm text-gray-400">
                  Aktiivinen varauskausi
                </p>
              </div>
            </div>
            <div className="hidden md:block px-4 py-2 bg-gray-900/50 rounded-lg">
              <p className="text-sm text-gray-300">
                {activeSeasonState.start_date && activeSeasonState.end_date ? (
                  `${new Date(activeSeasonState.start_date).toLocaleDateString('fi-FI')} - ${new Date(activeSeasonState.end_date).toLocaleDateString('fi-FI')}`
                ) : (
                  'Päivämäärät puuttuvat'
                )}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="bg-gray-800 rounded-lg shadow-lg p-6">
        {/* Hakukenttä */}
        <div className="mb-6 max-w-full">
          <div className="relative w-full sm:w-64 mx-auto">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => {
                const newValue = e.target.value;
                setSearchTerm(newValue);
                handleSearch(newValue);
              }}
              placeholder="Hae nimeä tai yritystä..."
              className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg 
                border border-gray-600 focus:ring-2 focus:ring-blue-500 
                focus:border-transparent outline-none transition-all duration-200
                placeholder-gray-400"
            />
            {searchTerm && (
              <button
                onClick={() => {
                  setSearchTerm('');
                  handleSearch('');
                }}
                className="absolute right-2 top-1/2 -translate-y-1/2 
                  text-gray-400 hover:text-white transition-colors duration-200"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            )}
          </div>
        </div>

        {/* Zoom-kontrollit */}
        <div className="flex justify-center gap-4 mb-4 max-w-full overflow-x-auto">
          <button
            onClick={handleZoomOut}
            className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 text-white transition-colors flex-shrink-0"
            aria-label="Loitonna"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
            </svg>
          </button>
          <button
            onClick={handleZoomIn}
            className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 text-white transition-colors flex-shrink-0"
            aria-label="Lähennä"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
          </button>
        </div>

        {/* Katsomon container */}
        <div 
          className="w-full overflow-hidden"
          style={{
            touchAction: 'none',
            userSelect: 'none'
          }}
        >
          {/* Zoomattava katsomo */}
          <div
            ref={mapRef}
            className="flex flex-col items-center transition-transform duration-200 ease-out"
            style={{
              transform: `scale(${zoomLevel}) translate(${dragPosition.x}px, ${dragPosition.y}px)`,
              transformOrigin: 'center center'
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {/* A-katsomo */}
            <div className="w-full overflow-x-auto mb-8 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800">
              <div className="min-w-max px-4">
                <div className="text-gray-300 text-center text-sm sm:text-base mb-1 sm:mb-2">
                  A-katsomo
                </div>
                {/* {createASection()} */}
              </div>
            </div>

            {/* B- ja C-katsomo */}
            <div 
              className="flex flex-row justify-center gap-4 min-w-max px-4 mb-8"
              style={{
                gap: window.innerWidth < MOBILE_BREAKPOINT ? '1rem' : '4rem'
              }}
            >
              <div className="flex flex-col gap-0.5">
                <div className="text-gray-300 text-center text-sm sm:text-base mb-1 sm:mb-2">
                  B-katsomo
                </div>
                {createBSection()}
              </div>
              <div className="flex flex-col gap-0.5">
                <div className="text-gray-300 text-center text-sm sm:text-base mb-1 sm:mb-2">
                  C-katsomo
                </div>
                {createCSection()}
              </div>
            </div>

            {/* D-katsomo */}
            <div className="w-full overflow-x-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800">
              <div className="min-w-max px-4">
                <div className="text-gray-300 text-center text-sm sm:text-base mb-1 sm:mb-2">
                  D-katsomo
                </div>
                {/* {createDSection()} */}
              </div>
            </div>
          </div>
        </div>

        <Legend />
        {renderTooltip()}
        
        {isModalOpen && (
          <ReservationModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSubmit={handleReservationSubmit}
          />
        )}
      </div>
    </div>
  );
};

SeatMap.propTypes = {
  onSeatClick: PropTypes.func.isRequired,
  reservations: PropTypes.object,
  seats: PropTypes.array.isRequired,
  activeSeason: PropTypes.object
};

export default SeatMap;