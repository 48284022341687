import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabaseClient';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell
} from 'recharts';

const Statistics = () => {
  const [stats, setStats] = useState({
    total: 393,
    sections: {
      A: { total: 144, reserved: 0 },
      B: { total: 58, reserved: 0 },
      C: { total: 61, reserved: 0 },
      D: { total: 130, reserved: 0 }
    },
    byType: {
      reserved: 0,
      partner: 0,
      guest: 0,
      other: 0
    }
  });

  const [sectionData, setSectionData] = useState([]);

  // Lisää uusi hook viewportin koon seuraamiseen
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Lisää useEffect kuuntelemaan ikkunan koon muutoksia
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Laske graafien koko dynaamisesti
  const getChartDimensions = () => {
    if (windowWidth < 640) { // mobile
      return { width: windowWidth - 60, height: 300 };
    } else if (windowWidth < 1024) { // tablet
      return { width: windowWidth / 2 - 80, height: 300 };
    } else { // desktop
      return { width: 500, height: 300 };
    }
  };

  useEffect(() => {
    fetchReservations();
    fetchStatistics();
  }, []);

  const fetchReservations = async () => {
    try {
      // Haetaan B- ja C-katsomon varaukset
      const { data: reservations, error: reservationsError } = await supabase
        .from('reservations')
        .select('*');

      // Haetaan A- ja D-katsomon varaukset
      const { data: otherReservations, error: otherReservationsError } = await supabase
        .from('other_reservations')
        .select('*');

      if (reservationsError) throw reservationsError;
      if (otherReservationsError) throw otherReservationsError;

      // Lasketaan tilastot
      const newStats = {
        total: 393,
        sections: {
          A: { total: 144, reserved: 0 },
          B: { total: 58, reserved: 0 },
          C: { total: 61, reserved: 0 },
          D: { total: 130, reserved: 0 }
        },
        byType: {
          reserved: 0,
          partner: 0,
          guest: 0,
          other: 0
        }
      };

      // Lasketaan B- ja C-katsomon varaukset
      reservations?.forEach(reservation => {
        const section = reservation.seat_id.charAt(0);
        if (newStats.sections[section]) {
          newStats.sections[section].reserved++;
        }
        
        const type = reservation.reservation_type || 'other';
        newStats.byType[type]++;
      });

      // Lasketaan A- ja D-katsomon varaukset
      otherReservations?.forEach(reservation => {
        const section = reservation.section === 'A-katsomo' ? 'A' : 'D';
        newStats.sections[section].reserved++;
        
        const type = reservation.reservation_type || 'other';
        newStats.byType[type]++;
      });

      setStats(newStats);
    } catch (error) {
      console.error('Error fetching statistics:', error);
    }
  };

  const fetchStatistics = async () => {
    try {
      // Haetaan A- ja D-katsomon varaukset (section-sarake)
      const { data: adData, error: adError } = await supabase
        .from('other_reservations')
        .select('section');

      // Haetaan B- ja C-katsomon varaukset (seat_id-sarake)
      const { data: bcData, error: bcError } = await supabase
        .from('reservations')
        .select('seat_id');

      if (adError || bcError) throw adError || bcError;

      // Lasketaan varaukset per katsomo
      const sectionCounts = {
        'A-katsomo': 0,
        'B-katsomo': 0,
        'C-katsomo': 0,
        'D-katsomo': 0
      };

      // Lasketaan A- ja D-katsomon varaukset section-sarakkeen perusteella
      adData.forEach(reservation => {
        if (reservation.section === 'A-katsomo') {
          sectionCounts['A-katsomo']++;
        } else if (reservation.section === 'D-katsomo') {
          sectionCounts['D-katsomo']++;
        }
      });

      // Lasketaan B- ja C-katsomon varaukset seat_id:n ensimmäisen kirjaimen perusteella
      bcData.forEach(reservation => {
        const section = reservation.seat_id.charAt(0);
        if (section === 'B') {
          sectionCounts['B-katsomo']++;
        } else if (section === 'C') {
          sectionCounts['C-katsomo']++;
        }
      });

      const chartData = Object.entries(sectionCounts).map(([name, value]) => ({
        name,
        varaukset: value
      }));

      console.log('Chart data:', chartData); // Debug-tuloste
      setSectionData(chartData);
    } catch (error) {
      console.error('Error fetching statistics:', error);
    }
  };

  // Värit graafeihin
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-gray-800 rounded-lg shadow-lg p-6">
        <h1 className="text-3xl font-bold text-white mb-8 border-b border-gray-700 pb-4">Statistiikka</h1>
        
        {/* Kokonaistilanne */}
        <div className="grid grid-cols-1 md:grid-cols-5 gap-6 mb-8">
          <div className="bg-gradient-to-br from-gray-700 to-gray-800 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1">
            <h2 className="text-xl font-bold text-white mb-4 border-b border-gray-600 pb-2">Kokonaistilanne</h2>
            <div className="space-y-3">
              <div className="flex justify-between items-center">
                <span className="text-gray-300">Paikkoja yhteensä:</span>
                <span className="text-2xl font-bold text-blue-400">{stats.total}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-300">Varattuja paikkoja:</span>
                <span className="text-2xl font-bold text-green-400">
                  {Object.values(stats.sections).reduce((acc, curr) => acc + curr.reserved, 0)}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-300">Vapaita paikkoja:</span>
                <span className="text-2xl font-bold text-yellow-400">
                  {stats.total - Object.values(stats.sections).reduce((acc, curr) => acc + curr.reserved, 0)}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-300">Täyttöaste:</span>
                <span className="text-2xl font-bold text-purple-400">
                  {((Object.values(stats.sections).reduce((acc, curr) => acc + curr.reserved, 0) / stats.total) * 100).toFixed(1)}%
                </span>
              </div>
            </div>
          </div>

          {/* Katsomolohkot */}
          {Object.entries(stats.sections).map(([section, data]) => (
            <div key={section} 
                 className="bg-gradient-to-br from-gray-700 to-gray-800 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1">
              <h2 className="text-xl font-bold text-white mb-4 border-b border-gray-600 pb-2">{section}-katsomo</h2>
              <div className="space-y-3">
                <div className="flex justify-between items-center">
                  <span className="text-gray-300">Paikkoja:</span>
                  <span className="text-2xl font-bold text-blue-400">{data.total}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-300">Varattuja:</span>
                  <span className="text-2xl font-bold text-green-400">{data.reserved}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-300">Vapaita:</span>
                  <span className="text-2xl font-bold text-yellow-400">{data.total - data.reserved}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-300">Täyttöaste:</span>
                  <span className="text-2xl font-bold text-purple-400">{((data.reserved / data.total) * 100).toFixed(1)}%</span>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Varaukset tyypeittäin */}
        <div className="bg-gradient-to-br from-gray-700 to-gray-800 p-6 rounded-xl shadow-lg">
          <h2 className="text-xl font-bold text-white mb-6 border-b border-gray-600 pb-2">Varaukset tyypeittäin</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
            <div className="bg-gray-800 p-4 rounded-lg hover:bg-gray-750 transition-colors text-center">
              <p className="text-gray-300 mb-2">Varatut paikat</p>
              <p className="text-3xl font-bold text-red-400">{stats.byType.reserved}</p>
            </div>
            <div className="bg-gray-800 p-4 rounded-lg hover:bg-gray-750 transition-colors text-center">
              <p className="text-gray-300 mb-2">Kumppanit</p>
              <p className="text-3xl font-bold text-blue-400">{stats.byType.partner}</p>
            </div>
            <div className="bg-gray-800 p-4 rounded-lg hover:bg-gray-750 transition-colors text-center">
              <p className="text-gray-300 mb-2">Kausikortit</p>
              <p className="text-3xl font-bold text-yellow-400">{stats.byType.guest}</p>
            </div>
            <div className="bg-gray-800 p-4 rounded-lg hover:bg-gray-750 transition-colors text-center">
              <p className="text-gray-300 mb-2">Muut</p>
              <p className="text-3xl font-bold text-gray-400">{stats.byType.other}</p>
            </div>
          </div>
        </div>

        {/* Lisätään graafit */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
          {/* Pylväskaavio */}
          <div className="bg-gray-700 p-4 rounded-lg overflow-x-auto">
            <h3 className="text-xl font-semibold text-white mb-4">Varaukset katsomoittain</h3>
            <div className="flex justify-center">
              <BarChart
                width={getChartDimensions().width}
                height={getChartDimensions().height}
                data={sectionData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" stroke="#fff" />
                <YAxis stroke="#fff" />
                <Tooltip 
                  cursor={false}
                  contentStyle={{ 
                    backgroundColor: '#000',
                    border: '1px solid #333',
                    borderRadius: '4px',
                    padding: '8px'
                  }}
                  itemStyle={{ color: '#ffffff' }}
                  labelStyle={{ color: '#ffffff', marginBottom: '4px' }}
                />
                <Legend />
                <Bar dataKey="varaukset" fill="#8884d8" />
              </BarChart>
            </div>
          </div>

          {/* Piirakkakaavio */}
          <div className="bg-gray-700 p-4 rounded-lg overflow-x-auto">
            <h3 className="text-xl font-semibold text-white mb-4">Varausten jakauma</h3>
            <div className="flex justify-center">
              <PieChart 
                width={getChartDimensions().width}
                height={getChartDimensions().height}
              >
                <Pie
                  data={sectionData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={Math.min(getChartDimensions().width, getChartDimensions().height) / 3}
                  fill="#8884d8"
                  dataKey="varaukset"
                >
                  {sectionData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip 
                  contentStyle={{ 
                    backgroundColor: '#000',
                    border: '1px solid #333',
                    borderRadius: '4px',
                    padding: '8px'
                  }}
                  itemStyle={{ color: '#ffffff' }}
                  labelStyle={{ color: '#ffffff', marginBottom: '4px' }}
                />
              </PieChart>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics; 