import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChangePasswordModal from './ChangePasswordModal';

const Navbar = ({ onLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const location = useLocation();
  const username = localStorage.getItem('username');

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="bg-gray-800 shadow-lg">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          {/* Logo/Brand muutettu linkiksi */}
          <div className="flex items-center">
            <Link 
              to="/"
              className="text-white text-lg font-semibold hover:text-gray-300 transition-colors"
            >
              KatsomoApp
            </Link>
          </div>

          {/* Hampurilaisvalikko-painike (näkyy vain mobiililla) */}
          <div className="flex items-center md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-300 hover:text-white p-2"
              aria-label="Avaa valikko"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isMenuOpen ? (
                  <path d="M6 18L18 6M6 6l12 12" />
                ) : (
                  <path d="M4 6h16M4 12h16M4 18h16" />
                )}
              </svg>
            </button>
          </div>

          {/* Desktop-valikko */}
          <div className="hidden md:flex items-center space-x-4">
            <Link
              to="/"
              className={`px-3 py-2 rounded-md text-sm font-medium ${
                isActive('/') 
                  ? 'bg-gray-900 text-white' 
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white'
              }`}
            >
              Katsomo
            </Link>
            <Link
              to="/reservations"
              className={`px-3 py-2 rounded-md text-sm font-medium ${
                isActive('/reservations')
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white'
              }`}
            >
              B- ja C-katsomo
            </Link>
            <Link
              to="/other-reservations"
              className={`px-3 py-2 rounded-md text-sm font-medium ${
                isActive('/other-reservations')
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white'
              }`}
            >
              A- ja D-katsomo
            </Link>
            <Link
              to="/statistics"
              className={`px-3 py-2 rounded-md text-sm font-medium ${
                isActive('/statistics')
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white'
              }`}
            >
              Statistiikka
            </Link>
            <Link
              to="/reports"
              className={`px-3 py-2 rounded-md text-sm font-medium ${
                isActive('/reports')
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white'
              }`}
            >
              Raportit
            </Link>
            <Link
              to="/seasons"
              className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              Kausien hallinta
            </Link>
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setIsPasswordModalOpen(true)}
                className="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                {username}
              </button>
              <button
                onClick={onLogout}
                className="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                Kirjaudu ulos
              </button>
            </div>
          </div>
        </div>

        {/* Mobiilivalikko */}
        <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link
              to="/"
              className={`block px-3 py-2 rounded-md text-base font-medium ${
                isActive('/')
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white'
              }`}
              onClick={() => setIsMenuOpen(false)}
            >
              Katsomo
            </Link>
            <Link
              to="/reservations"
              className={`block px-3 py-2 rounded-md text-base font-medium ${
                isActive('/reservations')
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white'
              }`}
              onClick={() => setIsMenuOpen(false)}
            >
              B- ja C-katsomo
            </Link>
            <Link
              to="/other-reservations"
              className={`block px-3 py-2 rounded-md text-base font-medium ${
                isActive('/other-reservations')
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white'
              }`}
              onClick={() => setIsMenuOpen(false)}
            >
              A- ja D-katsomo
            </Link>
            <Link
              to="/statistics"
              className={`block px-3 py-2 rounded-md text-base font-medium ${
                isActive('/statistics')
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white'
              }`}
              onClick={() => setIsMenuOpen(false)}
            >
              Statistiikka
            </Link>
            <Link
              to="/reports"
              className={`block px-3 py-2 rounded-md text-base font-medium ${
                isActive('/reports')
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white'
              }`}
              onClick={() => setIsMenuOpen(false)}
            >
              Raportit
            </Link>
            <Link
              to="/seasons"
              className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              Kausien hallinta
            </Link>
            <button
              onClick={() => {
                setIsPasswordModalOpen(true);
                setIsMenuOpen(false);
              }}
              className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
            >
              {username}
            </button>
            <button
              onClick={() => {
                setIsMenuOpen(false);
                onLogout();
              }}
              className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
            >
              Kirjaudu ulos
            </button>
          </div>
        </div>
      </div>

      {/* Salasanan vaihto -modaali */}
      <ChangePasswordModal
        isOpen={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
        username={username}
      />
    </nav>
  );
};

Navbar.propTypes = {
  onLogout: PropTypes.func.isRequired
};

export default Navbar; 