import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { supabase } from './lib/supabaseClient';
import Login from './components/Login';
import Navbar from './components/NavBar';
import SeatMapView from './views/SeatMapView';
import ReservationList from './components/ReservationList';
import OtherReservationList from './components/OtherReservationList';
import Statistics from './components/Statistics';
import Reports from './components/Reports';
import SeasonManagement from './components/SeasonManagement';

// AuthenticatedApp-komponentti
function AuthenticatedApp({ onLogout, session }) {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      localStorage.removeItem('username');
      navigate('/');
      onLogout();
    } catch (error) {
      console.error('Virhe uloskirjautumisessa:', error.message);
    }
  };

  return (
    <>
      <Navbar onLogout={handleLogout} />
      <Routes>
        <Route path="/" element={<SeatMapView />} />
        <Route path="/reservations" element={<ReservationList />} />
        <Route path="/other-reservations" element={<OtherReservationList />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/reports" element={<Reports session={session} />} />
        <Route path="/seasons" element={<SeasonManagement />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}

// Lisätään PropTypes määrittely
AuthenticatedApp.propTypes = {
  onLogout: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired
};

// App-komponentti
function App() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (loading) {
    return <div>Ladataan...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-900">
      {session ? (
        <AuthenticatedApp 
          onLogout={() => setSession(null)} 
          session={session}
        />
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </div>
  );
}

// AppWrapper pysyy samana
function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;