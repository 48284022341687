import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabaseClient';
import SeatMap from '../components/SeatMap';
import ReservationModal from '../components/ReservationModal';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal';

const SeatMapView = () => {
  const [reservations, setReservations] = useState({
    other: [],
    bc: []
  });
  const [loading, setLoading] = useState(true);
  const [editingReservation, setEditingReservation] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        setLoading(true);
        
        const [otherResult, bcResult] = await Promise.all([
          supabase.from('other_reservations').select('*'),
          supabase.from('reservations').select('*')
        ]);

        setReservations({
          other: otherResult.data || [],
          bc: bcResult.data || []
        });

      } catch (error) {
        console.error('Virhe varausten haussa:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReservations();
  }, []);

  // Muunnetaan varaukset SeatMap-komponentin tarvitsemaan muotoon
  const formatReservationsForSeatMap = () => {
    const formattedReservations = {};
    
    // Käsitellään B ja C katsomon varaukset
    reservations.bc.forEach(reservation => {
      if (reservation.seat_id) {
        formattedReservations[reservation.seat_id] = reservation;
      }
    });

    // Käsitellään A ja D katsomon varaukset
    reservations.other.forEach(reservation => {
      if (reservation.seat_id) {
        formattedReservations[reservation.seat_id] = reservation;
      }
    });

    return formattedReservations;
  };

  const handleSeatClick = (seatId) => {
    const formattedReservations = formatReservationsForSeatMap();
    const reservation = formattedReservations[seatId];
    
    if (reservation) {
      setEditingReservation(reservation);
    } else {
      // Jos paikka on vapaa, voidaan lisätä uusi varaus
      setEditingReservation({ seat_id: seatId });
    }
  };

  const handleEdit = async (formData) => {
    try {
      // Tarkista istunto ennen tallennusta
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('Ei aktiivista istuntoa');
      }

      const reservationData = {
        seat_id: editingReservation.seat_id,
        name: formData.name,
        email: formData.email,
        company: formData.company,
        mobile: formData.mobile,
        other_info: formData.other_info,
        season: formData.season,
        season_id: formData.season_id,
        reservation_type: formData.reservationType
      };

      let error;
      
      // Tallennetaan varaus
      if (!editingReservation.id) {
        const result = await supabase
          .from('reservations')
          .insert([reservationData]);
        error = result.error;
      } else {
        const result = await supabase
          .from('reservations')
          .update(reservationData)
          .eq('id', editingReservation.id);
        error = result.error;
      }

      if (error) throw error;

      // Sulje modaali ensin
      setEditingReservation(null);

      // Päivitä varaukset erillisessä try-catch -lohkossa
      try {
        const [otherResult, bcResult] = await Promise.all([
          supabase.from('other_reservations').select('*'),
          supabase.from('reservations').select('*')
        ]);

        setReservations({
          other: otherResult.data || [],
          bc: bcResult.data || []
        });
      } catch (fetchError) {
        console.warn('Varausten päivitys epäonnistui:', fetchError);
        // Ei näytetä virheilmoitusta käyttäjälle, koska tallennus onnistui
      }

    } catch (error) {
      console.error('Virhe varauksen tallennuksessa:', error);
      
      if (error.message === 'Ei aktiivista istuntoa') {
        alert('Istunto on vanhentunut. Kirjaudu sisään uudelleen.');
        return;
      }
      
      alert('Virhe varauksen tallennuksessa. Yritä uudelleen.');
    }
  };

  const handleDelete = async () => {
    try {
      const { error } = await supabase
        .from('reservations')
        .delete()
        .eq('seat_id', editingReservation.seat_id);

      if (error) {
        console.error('Virhe varauksen poistossa:', error);
        throw error;
      }

      // Päivitä varaukset
      const [otherResult, bcResult] = await Promise.all([
        supabase.from('other_reservations').select('*'),
        supabase.from('reservations').select('*')
      ]);

      setReservations({
        other: otherResult.data || [],
        bc: bcResult.data || []
      });

      // Sulje modaalit
      setShowDeleteConfirmation(false);
      setEditingReservation(null);
    } catch (error) {
      console.error('Virhe varauksen poistossa:', error);
    }
  };

  // Lisätään funktio tilastojen laskemiseen
  const calculateSectionStats = (section) => {
    const totalSeats = section === 'B' ? 58 : 61; // B:llä 58, C:llä 61 paikkaa
    const reserved = reservations.bc.filter(r => r.seat_id.startsWith(section)).length;
    
    return {
      total: totalSeats,
      reserved: reserved,
      available: totalSeats - reserved,
      occupancyRate: ((reserved / totalSeats) * 100).toFixed(1)
    };
  };

  if (loading) {
    return <div>Ladataan...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <SeatMap 
        reservations={formatReservationsForSeatMap()}
        onSeatClick={handleSeatClick}
      />

      {/* Tilastokortit */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
        {['B', 'C'].map((section) => {
          const stats = calculateSectionStats(section);
          return (
            <div key={section}
                 className="bg-gradient-to-br from-gray-700 to-gray-800 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1">
              <h2 className="text-xl font-bold text-white mb-4 border-b border-gray-600 pb-2">{section}-katsomo</h2>
              <div className="space-y-3">
                <div className="flex justify-between items-center">
                  <span className="text-gray-300">Paikkoja:</span>
                  <span className="text-2xl font-bold text-blue-400">{stats.total}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-300">Varattuja:</span>
                  <span className="text-2xl font-bold text-green-400">{stats.reserved}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-300">Vapaita:</span>
                  <span className="text-2xl font-bold text-yellow-400">{stats.available}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-300">Täyttöaste:</span>
                  <span className="text-2xl font-bold text-purple-400">{stats.occupancyRate}%</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Modaalit */}
      {editingReservation && (
        <ReservationModal
          isOpen={!!editingReservation}
          onClose={() => setEditingReservation(null)}
          onSubmit={handleEdit}
          onDelete={() => setShowDeleteConfirmation(true)}
          existingData={editingReservation}
          seatId={editingReservation.seat_id}
        />
      )}

      <DeleteConfirmationModal
        isOpen={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={() => {
          handleDelete();
          setShowDeleteConfirmation(false);
        }}
        reservationDetails={editingReservation || {}}
      />
    </div>
  );
};

export default SeatMapView; 