import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const OtherReservationModal = ({ isOpen, onClose, onSubmit, onDelete, existingData, activeSeason }) => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    mobile: '',
    other_info: '',
    section: 'A',
    season: '',
    reservation_type: 'reserved'
  });

  useEffect(() => {
    if (existingData) {
      setFormData({
        ...existingData,
        section: existingData.section === 'A-katsomo' ? 'A' : 'D',
        mobile: existingData.mobile || '',
        other_info: existingData.other_info || ''
      });
    } else if (activeSeason) {
      setFormData(prev => ({
        ...prev,
        season: activeSeason
      }));
    }
  }, [existingData, activeSeason]);

  const handleInputChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-gray-800 p-6 rounded-lg max-w-md w-full mx-4">
        <h2 className="text-xl font-bold text-white mb-4">
          {existingData?.id ? 'Muokkaa varausta' : 'Lisää uusi varaus'}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-300 mb-2">Nimi</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full p-2 bg-gray-700 text-white rounded"
              required
            />
          </div>

          <div>
            <label className="block text-gray-300 mb-2">Sähköposti</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full p-2 bg-gray-700 text-white rounded"
              required
            />
          </div>

          <div>
            <label className="block text-gray-300 mb-2">Yritys</label>
            <input
              type="text"
              name="company"
              value={formData.company}
              onChange={handleInputChange}
              className="w-full p-2 bg-gray-700 text-white rounded"
            />
          </div>

          <div className="relative">
            <label className="block text-gray-300 mb-2">Kausi</label>
            <div className="relative">
              <input
                type="text"
                name="season"
                value={formData.season}
                className="w-full p-2 bg-gray-700 text-white rounded cursor-not-allowed"
                disabled
              />
              <div className="absolute right-3 top-1/2 -translate-y-1/2">
                <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse"></div>
              </div>
            </div>
          </div>

          <div>
            <label className="block text-gray-300 mb-2">Varauksen tyyppi</label>
            <select
              name="reservation_type"
              value={formData.reservation_type}
              onChange={handleInputChange}
              className="w-full p-2 bg-gray-700 text-white rounded"
            >
              <option value="reserved">Varattu paikka</option>
              <option value="partner">Kumppani</option>
              <option value="guest">Kausikortti Lippu.fi</option>
              <option value="other">Muu</option>
            </select>
          </div>

          <div>
            <label className="block text-gray-300 mb-1">Katsomo</label>
            <select
              name="section"
              value={formData.section}
              onChange={handleInputChange}
              className="w-full p-2 bg-gray-700 text-white rounded"
            >
              <option value="A">A-katsomo</option>
              <option value="D">D-katsomo</option>
            </select>
          </div>

          <div>
            <label className="block text-gray-300 mb-2">Puhelin</label>
            <input
              type="tel"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              className="w-full p-2 bg-gray-700 text-white rounded"
            />
          </div>

          <div>
            <label className="block text-gray-300 mb-2">Lisätietoja</label>
            <textarea
              name="other_info"
              value={formData.other_info}
              onChange={handleInputChange}
              rows="3"
              className="w-full p-2 bg-gray-700 text-white rounded resize-y"
            />
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-600 text-white text-sm font-medium rounded-md
                hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Peruuta
            </button>
            
            {existingData && (
              <button
                type="button"
                onClick={onDelete}
                className="px-4 py-2 bg-red-600 text-white text-sm font-medium rounded-md
                  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Poista
              </button>
            )}

            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md
                hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {existingData ? 'Tallenna muutokset' : 'Lisää'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

OtherReservationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  existingData: PropTypes.object,
  activeSeason: PropTypes.string
};

export default OtherReservationModal; 