import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabaseClient';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';

const SeasonManagement = () => {
  const [seasons, setSeasons] = useState([]);
  const [newSeason, setNewSeason] = useState({
    name: '',
    start_date: '',
    end_date: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Haetaan kaudet kun komponentti latautuu
  useEffect(() => {
    fetchSeasons();
  }, []); // Tyhjä dependency array tarkoittaa että ajetaan vain kerran komponentin mountissa

  const fetchSeasons = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('seasons')
        .select('*')
        .order('start_date', { ascending: false });

      if (error) throw error;
      
      console.log('Haetut kaudet:', data); // Debug-tuloste
      setSeasons(data);
    } catch (err) {
      setError('Kausien hakeminen epäonnistui');
      console.error('Virhe kausien haussa:', err);
    } finally {
      setLoading(false);
    }
  };

  // Lisää uusi kausi
  const addSeason = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('seasons')
        .insert([newSeason])
        .select();

      if (error) throw error;
      setSeasons([...seasons, data[0]]);
      setNewSeason({ name: '', start_date: '', end_date: '' });
    } catch (err) {
      setError('Kauden lisääminen epäonnistui');
    } finally {
      setLoading(false);
    }
  };

  // Aktivoi kausi
  const activateSeason = async (id) => {
    try {
      // Ensin deaktivoidaan kaikki kaudet
      await supabase
        .from('seasons')
        .update({ is_active: false })
        .neq('id', id);

      // Sitten aktivoidaan valittu kausi
      const { error } = await supabase
        .from('seasons')
        .update({ is_active: true, status: 'active' })
        .eq('id', id);

      if (error) throw error;
      fetchSeasons();
    } catch (err) {
      setError('Kauden aktivointi epäonnistui');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-gray-800 rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold text-white mb-6">Varauskausien hallinta</h2>

        {/* Uuden kauden lisäyslomake */}
        <form onSubmit={addSeason} className="mb-8 space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-300">Kauden nimi</label>
              <input
                type="text"
                value={newSeason.name}
                onChange={(e) => setNewSeason({...newSeason, name: e.target.value})}
                className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300">Alkupäivä</label>
              <input
                type="date"
                value={newSeason.start_date}
                onChange={(e) => setNewSeason({...newSeason, start_date: e.target.value})}
                className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300">Loppupäivä</label>
              <input
                type="date"
                value={newSeason.end_date}
                onChange={(e) => setNewSeason({...newSeason, end_date: e.target.value})}
                className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                required
              />
            </div>
          </div>
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
          >
            {loading ? 'Lisätään...' : 'Lisää uusi kausi'}
          </button>
        </form>

        {/* Kausien listaus */}
        <div className="space-y-4">
          {seasons.map((season) => (
            <div
              key={season.id}
              className={`p-4 rounded-lg ${
                season.is_active ? 'bg-green-900/20' : 'bg-gray-700'
              }`}
            >
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-lg font-semibold text-white">{season.name}</h3>
                  <p className="text-gray-300">
                    {format(new Date(season.start_date), 'd.M.yyyy', { locale: fi })} - 
                    {format(new Date(season.end_date), 'd.M.yyyy', { locale: fi })}
                  </p>
                  <span className={`inline-block px-2 py-1 rounded text-sm ${
                    season.is_active ? 'bg-green-600' : 'bg-gray-600'
                  }`}>
                    {season.is_active ? 'Aktiivinen' : 'Ei aktiivinen'}
                  </span>
                </div>
                {!season.is_active && (
                  <button
                    onClick={() => activateSeason(season.id)}
                    className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                  >
                    Aktivoi kausi
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SeasonManagement; 