import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { supabase } from '../lib/supabaseClient';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: username,
        password: password
      });

      if (error) throw error;

      if (data.session) {
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('username', username);
        onLogin(true, username);
        navigate('/');
      } else {
        setError('Väärä käyttäjätunnus tai salasana');
      }
    } catch (err) {
      console.error('Kirjautumisvirhe:', err);
      setError('Kirjautuminen epäonnistui');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col md:flex-row">
      {/* Login form - täysi leveys mobiililla, puolet desktopilla */}
      <div className="w-full md:w-1/2 flex items-center justify-center p-4 md:p-8">
        <div className="w-full max-w-md">
          <h1 className="text-2xl md:text-3xl font-bold text-white mb-6 md:mb-8 text-center md:text-left">
            Kirjaudu sisään
          </h1>

          <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-300">
                Käyttäjätunnus
              </label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm 
                  text-white placeholder-gray-500
                  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Syötä käyttäjätunnus"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300">
                Salasana
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm 
                  text-white placeholder-gray-500
                  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Syötä salasana"
                required
              />
            </div>

            {error && (
              <div className="text-red-400 text-sm text-center bg-red-900/50 py-2 rounded-md">
                {error}
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              className="w-full py-2.5 md:py-3 bg-blue-600 text-white rounded-md font-medium
                hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                disabled:opacity-50 disabled:cursor-not-allowed transition-colors
                focus:ring-offset-gray-900"
            >
              {loading ? 'Kirjaudutaan...' : 'Kirjaudu sisään'}
            </button>
          </form>
        </div>
      </div>

      {/* Gradient tausta ja kuva - piilotettu mobiililla */}
      <div className="hidden md:block w-full md:w-1/2 bg-gradient-to-br from-gray-800 via-purple-900 to-gray-800 relative">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-500/10 via-purple-500/10 to-blue-500/10 animate-gradient"></div>
        </div>
        
        <div className="absolute inset-0 flex items-center justify-center">
          <img
            src="/katsomoapp/Hurmos_logo.png"
            alt="Login illustration"
            className="w-2/3 h-auto max-w-md opacity-75"
          />
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  onLogin: PropTypes.func.isRequired
};

export default Login;