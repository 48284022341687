import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabaseClient';
import { format } from 'date-fns';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import ReservationModal from './ReservationModal';
import PropTypes from 'prop-types';

// Lisätään apufunktio tyypin muuntamiseen
const getReservationTypeInFinnish = (type) => {
  const types = {
    'reserved': 'Varattu paikka',
    'partner': 'Kumppani',
    'guest': 'Kausikortti Lippu.fi',
    'other': 'Muu'
  };
  return types[type] || type;
};

const ReservationList = () => {
  // Lisätään columns-määrittely heti komponentin alkuun
  const columns = [
    { key: 'actions', label: 'Toiminnot', width: 'w-24' },
    { key: 'seat_id', label: 'Paikka', width: 'w-20' },
    { key: 'name', label: 'Nimi', width: 'w-32' },
    { key: 'mobile', label: 'Puhelin', width: 'w-32' },
    { key: 'email', label: 'Sähköposti', width: 'w-44' },
    { key: 'reservation_type', label: 'Tyyppi', width: 'w-32' },
    { key: 'company', label: 'Yritys', width: 'w-32' },
    { key: 'season', label: 'Kausi', width: 'w-24' },
    { key: 'other_info', label: 'Lisätiedot', width: 'w-40' }
  ];

  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ 
    key: 'seat_id', 
    direction: 'ascending'
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [reservationToDelete, setReservationToDelete] = useState(null);
  const [editingReservation, setEditingReservation] = useState(null);

  useEffect(() => {
    fetchReservations();
  }, []);

  const fetchReservations = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('reservations')
        .select('*');

      if (error) throw error;

      setReservations(data || []);
      setLoading(false);
    } catch (error) {
      console.error('Fetch error:', error);
      setError('Virhe varausten hakemisessa');
      setLoading(false);
    }
  };

  const handleDeleteClick = (reservation) => {
    setReservationToDelete(reservation);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const { error } = await supabase
        .from('reservations')
        .delete()
        .eq('id', reservationToDelete.id);

      if (error) throw error;
      await fetchReservations();
      setDeleteModalOpen(false);
      setReservationToDelete(null);
    } catch (error) {
      console.error('Delete error:', error);
      setError('Virhe varauksen poistamisessa');
    }
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Hakutoiminto
  const filteredReservations = reservations.filter(reservation => {
    const searchLower = searchTerm.toLowerCase();
    return (
      reservation.seat_id.toLowerCase().includes(searchLower) ||
      (reservation.name && reservation.name.toLowerCase().includes(searchLower)) ||
      (reservation.company && reservation.company.toLowerCase().includes(searchLower)) ||
      (reservation.email && reservation.email.toLowerCase().includes(searchLower)) ||
      (reservation.reservation_type && reservation.reservation_type.toLowerCase().includes(searchLower))
    );
  });

  // Järjestäminen
  const sortedReservations = [...filteredReservations].sort((a, b) => {
    if (a[sortConfig.key] === null) return 1;
    if (b[sortConfig.key] === null) return -1;
    
    let comparison = 0;
    if (a[sortConfig.key]?.toLowerCase() < b[sortConfig.key]?.toLowerCase()) {
      comparison = -1;
    }
    if (a[sortConfig.key]?.toLowerCase() > b[sortConfig.key]?.toLowerCase()) {
      comparison = 1;
    }
    return sortConfig.direction === 'desc' ? comparison * -1 : comparison;
  });

  const exportToCSV = () => {
    // CSV:n otsikkorivi
    const headers = [
      'Paikka',
      'Nimi',
      'Puhelin',
      'Sähköposti',
      'Tyyppi',
      'Yritys',
      'Kausi',
      'Lisätiedot'
    ];
    
    // Muunna data CSV-muotoon
    const csvData = sortedReservations.map(reservation => [
      reservation.seat_id || '',
      reservation.name || '',
      reservation.mobile || '',
      reservation.email || '',
      getReservationTypeInFinnish(reservation.reservation_type) || '',
      reservation.company || '',
      reservation.season || '',
      reservation.other_info || ''
    ]);
    
    // Yhdistä otsikot ja data
    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.join(','))
    ].join('\n');
    
    // Luo ja lataa tiedosto
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const timestamp = format(new Date(), 'yyyy-MM-dd_HHmm');
    
    link.setAttribute('href', url);
    link.setAttribute('download', `b_ja_c_katsomot_${timestamp}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEdit = async (formData) => {
    try {
      const { error } = await supabase
        .from('reservations')
        .update({
          name: formData.name,
          company: formData.company,
          email: formData.email,
          mobile: formData.mobile,
          other_info: formData.other_info,
          reservation_type: formData.reservation_type,
          season: formData.season
        })
        .eq('id', editingReservation.id);

      if (error) throw error;
      await fetchReservations();
      setEditingReservation(null);
    } catch (error) {
      console.error('Edit error:', error);
      setError('Virhe varauksen muokkaamisessa');
    }
  };

  // Lisätään SortIcon-komponentti
  const SortIcon = ({ column }) => {
    if (sortConfig.key !== column) {
      return <span className="ml-1 text-gray-500">↕</span>;
    }
    return <span className="ml-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>;
  };

  SortIcon.propTypes = {
    column: PropTypes.string.isRequired
  };

  if (loading) {
    return <div className="text-white text-center p-4">Ladataan...</div>;
  }

  if (error) {
    return (
      <div className="text-red-500 text-center p-4 bg-gray-800 rounded-lg m-4">
        <p>Virhe: {error}</p>
        <button 
          onClick={fetchReservations}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          Yritä uudelleen
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
        <h1 className="text-2xl font-bold text-white">Varaukset</h1>
        <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-4 w-full sm:w-auto">
          <div className="relative flex-grow sm:flex-grow-0">
            <input
              type="text"
              placeholder="Hae..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full sm:w-64 p-2 pr-10 bg-gray-700 text-white rounded border border-gray-600 
                focus:border-blue-500 focus:outline-none"
            />
            {searchTerm && (
              <button
                onClick={() => setSearchTerm('')}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 
                  text-gray-400 hover:text-white transition-colors"
              >
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" />
                </svg>
              </button>
            )}
          </div>
          <button
            onClick={exportToCSV}
            className="px-4 py-2 bg-yellow-600 hover:bg-yellow-700 text-white rounded 
              transition-colors flex items-center justify-center gap-2 w-full sm:w-auto"
          >
            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" />
            </svg>
            <span className="hidden sm:inline">Vie CSV</span>
          </button>
        </div>
      </div>

      <div className="bg-gray-800 rounded-lg shadow-lg border border-gray-700">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-700">
            <thead className="bg-gray-700">
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.key}
                    onClick={() => column.key !== 'actions' && handleSort(column.key)}
                    className={`px-3 py-2 text-left text-xs font-medium text-gray-300 uppercase tracking-wider 
                      cursor-pointer ${column.width} whitespace-nowrap`}
                  >
                    <div className="flex items-center gap-1">
                      {column.label}
                      {column.key !== 'actions' && <SortIcon column={column.key} />}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-gray-900 divide-y divide-gray-700">
              {sortedReservations.map((reservation) => (
                <tr 
                  key={reservation.id}
                  className="hover:bg-gray-50 hover:text-gray-900 transition-colors duration-150 ease-in-out group"
                >
                  <td className={`px-3 py-2 whitespace-nowrap text-sm text-gray-300 group-hover:text-gray-900 md:px-6 ${columns[0].width}`}>
                    <div className="flex gap-2">
                      <button
                        onClick={() => setEditingReservation(reservation)}
                        className="p-2 text-blue-500 hover:text-blue-400 transition-colors duration-150 rounded-full hover:bg-blue-500/10"
                        title="Muokkaa varausta"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                      </button>
                      <button
                        onClick={() => handleDeleteClick(reservation)}
                        className="p-2 text-red-500 hover:text-red-400 transition-colors duration-150 rounded-full hover:bg-red-500/10"
                        title="Poista varaus"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" />
                        </svg>
                      </button>
                    </div>
                  </td>
                  <td className={`px-3 py-2 whitespace-nowrap text-sm text-gray-300 group-hover:text-gray-900 truncate md:px-6 ${columns[1].width}`}>{reservation.seat_id}</td>
                  <td className={`px-3 py-2 whitespace-nowrap text-sm text-gray-300 group-hover:text-gray-900 truncate md:px-6 ${columns[2].width}`}>{reservation.name}</td>
                  <td className={`px-3 py-2 whitespace-nowrap text-sm text-gray-300 group-hover:text-gray-900 truncate md:px-6 ${columns[3].width}`}>{reservation.mobile}</td>
                  <td className={`px-3 py-2 whitespace-nowrap text-sm text-gray-300 group-hover:text-gray-900 truncate md:px-6 ${columns[4].width}`}>{reservation.email}</td>
                  <td className={`px-3 py-2 whitespace-nowrap text-sm text-gray-300 group-hover:text-gray-900 truncate md:px-6 ${columns[5].width}`}>{getReservationTypeInFinnish(reservation.reservation_type)}</td>
                  <td className={`px-3 py-2 whitespace-nowrap text-sm text-gray-300 group-hover:text-gray-900 truncate md:px-6 ${columns[6].width}`}>{reservation.company}</td>
                  <td className={`px-3 py-2 whitespace-nowrap text-sm text-gray-300 group-hover:text-gray-900 truncate md:px-6 ${columns[7].width}`}>{reservation.season}</td>
                  <td 
                    className={`px-3 py-2 whitespace-nowrap text-sm text-gray-300 group-hover:text-gray-900 truncate md:px-6 ${columns[8].width}`}
                    title={reservation.other_info}
                  >
                    {reservation.other_info}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setReservationToDelete(null);
        }}
        onConfirm={handleDeleteConfirm}
      />

      {editingReservation && (
        <ReservationModal
          isOpen={!!editingReservation}
          onClose={() => setEditingReservation(null)}
          onSubmit={handleEdit}
          existingData={editingReservation}
          onDelete={() => handleDeleteClick(editingReservation)}
        />
      )}
    </div>
  );
};

export default ReservationList;