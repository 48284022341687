import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { supabase } from '../lib/supabaseClient';
import html2pdf from 'html2pdf.js';

const Reports = ({ session }) => {
  const [adReservations, setAdReservations] = useState([]);
  const [bcReservations, setBcReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: 'created_at',
    direction: 'desc'
  });
  const pdfRef = useRef();

  // Apufunktio varaustyypin suomentamiseen
  const getReservationType = (type) => {
    const types = {
      'partner': 'Kumppani',
      'other': 'Muu',
      'reserved': 'Varattu paikka',
      'guest': 'Kausikortti Lippu.fi'
    };
    return types[type] || type;
  };

  // Lajittelufunktio
  const handleSort = (key, listType) => {
    const direction = 
      sortConfig.key === key && sortConfig.direction === 'asc' 
        ? 'desc' 
        : 'asc';
    
    setSortConfig({ key, direction });

    const sortData = (data) => {
      return [...data].sort((a, b) => {
        if (a[key] === null) return 1;
        if (b[key] === null) return -1;
        
        let compareA = a[key];
        let compareB = b[key];

        if (key === 'created_at') {
          compareA = new Date(a[key]);
          compareB = new Date(b[key]);
        }

        if (compareA < compareB) return direction === 'asc' ? -1 : 1;
        if (compareA > compareB) return direction === 'asc' ? 1 : -1;
        return 0;
      });
    };

    if (listType === 'ad') {
      setAdReservations(sortData(adReservations));
    } else {
      setBcReservations(sortData(bcReservations));
    }
  };

  // Lajittelun nuoli-indikaattori
  const getSortIndicator = (key) => {
    if (sortConfig.key !== key) return '↕️';
    return sortConfig.direction === 'asc' ? '↑' : '↓';
  };

  // Sarakkeen otsikkopainike
  const SortableHeader = ({ label, sortKey, listType }) => (
    <th 
      className="px-4 py-3 text-left text-sm font-medium text-gray-200 cursor-pointer hover:bg-gray-500"
      onClick={() => handleSort(sortKey, listType)}
    >
      <div className="flex items-center space-x-1">
        <span>{label}</span>
        <span className="text-gray-400">{getSortIndicator(sortKey)}</span>
      </div>
    </th>
  );

  // PropTypes määrittely SortableHeader-komponentille
  SortableHeader.propTypes = {
    label: PropTypes.string.isRequired,
    sortKey: PropTypes.string.isRequired,
    listType: PropTypes.oneOf(['ad', 'bc']).isRequired
  };

  // Varausten hakufunktiot
  const fetchADReservations = async () => {
    setLoading(true);
    try {
      if (!session) {
        console.log('Ei sessiota saatavilla');
        return;
      }

      const { data, error } = await supabase
        .from('other_reservations')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setAdReservations(data || []);
      setBcReservations([]);
    } catch (err) {
      console.error('Error fetching AD reservations:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchBCReservations = async () => {
    setLoading(true);
    try {
      if (!session) {
        console.log('Ei sessiota saatavilla');
        return;
      }

      const { data, error } = await supabase
        .from('reservations')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setBcReservations(data || []);
      setAdReservations([]);
    } catch (err) {
      console.error('Error fetching BC reservations:', err);
    } finally {
      setLoading(false);
    }
  };

  // Lisätään useEffect hook session-muutoksen seurantaan
  useEffect(() => {
    if (session) {
      console.log('Session on aktiivinen:', session);
    } else {
      console.log('Ei aktiivista sessiota');
    }
  }, [session]);

  // PDF:n luontifunktio A- ja D-katsomolle
  const generateADPDF = () => {
    const element = pdfRef.current;
    
    // Lisätään PDF-spesifiset tyylit
    const pdfStyles = `
      <style>
        @page { 
          margin: 1cm 2cm 1cm 3cm; /* ylä oikea ala vasen */
        }
        body { margin: 0; }
        .pdf-title {
          font-size: 18pt;
          font-weight: bold;
          color: #000;
          margin: 10px 0 20px 0;
          padding: 0;
          text-align: left;
        }
        table { 
          font-size: 8pt;
          width: 100%; 
          border-collapse: collapse;
          margin: 0;
          color: #000;
          table-layout: fixed;
        }
        th, td { 
          padding: 4px 6px; /* Pienennetty padding */
          border: 1px solid #333;
          color: #000; 
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        th { 
          background-color: #333; 
          color: #fff; 
          font-weight: bold;
          font-size: 9pt;
        }
        tr:nth-child(even) { 
          background-color: #e8e8e8;
        }
        tr:nth-child(odd) { 
          background-color: #fff;
        }
        td { 
          color: #000 !important;
        }
        /* Määritellään sarakeleveydet */
        th:nth-child(1), td:nth-child(1) { width: 12%; } /* Katsomo */
        th:nth-child(2), td:nth-child(2) { width: 15%; } /* Nimi */
        th:nth-child(3), td:nth-child(3) { width: 18%; } /* Yritys */
        th:nth-child(4), td:nth-child(4) { width: 22%; } /* Sähköposti */
        th:nth-child(5), td:nth-child(5) { width: 13%; } /* Varaustyyppi */
        th:nth-child(6), td:nth-child(6) { width: 10%; } /* Kausi */
        th:nth-child(7), td:nth-child(7) { width: 10%; } /* Luotu */
        
        h2 { 
          font-size: 14pt; 
          margin: 12px 6px; 
          color: #000;
          font-weight: bold;
        }
        .pdf-container {
          padding: 0;
          color: #000; 
          background-color: #fff;
        }
      </style>
    `;

    // Luodaan väliaikainen elementti PDF:ää varten
    const pdfContent = `
      ${pdfStyles}
      <div class="pdf-container" style="color: #000 !important;">
        <h1 class="pdf-title">2024-2025 A- ja D-katsomo</h1>
        ${element.innerHTML.replace(/text-gray-300/g, 'text-black')}
      </div>
    `;

    const opt = {
      margin: [28, 57, 28, 85], // [top, right, bottom, left] pisteinä
      filename: 'A- ja D-katsomo-varaukset.pdf',
      image: { 
        type: 'jpeg', 
        quality: 1
      },
      html2canvas: { 
        scale: 3,
        useCORS: true,
        letterRendering: true,
        logging: true,
        width: 1170,
        backgroundColor: '#FFF'
      },
      jsPDF: { 
        unit: 'pt',
        format: 'a4',
        orientation: 'landscape',
        compress: true,
        precision: 16
      }
    };

    html2pdf().set(opt).from(pdfContent).save();
  };

  // PDF:n luontifunktio B- ja C-katsomolle
  const generateBCPDF = () => {
    const element = pdfRef.current;
    
    // Lisätään PDF-spesifiset tyylit
    const pdfStyles = `
      <style>
        @page { 
          margin: 1cm 2cm 1cm 3cm; /* ylä oikea ala vasen */
        }
        body { margin: 0; }
        table { 
          font-size: 8pt;
          width: 100%; 
          border-collapse: collapse;
          margin: 0;
          color: #000;
          table-layout: fixed;
        }
        th, td { 
          padding: 4px 6px;
          border: 1px solid #333;
          color: #000; 
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        th { 
          background-color: #333; 
          color: #fff; 
          font-weight: bold;
          font-size: 9pt;
        }
        tr:nth-child(even) { 
          background-color: #e8e8e8;
        }
        tr:nth-child(odd) { 
          background-color: #fff;
        }
        td { 
          color: #000 !important;
        }
        /* Määritellään sarakeleveydet */
        th:nth-child(1), td:nth-child(1) { width: 12%; }
        th:nth-child(2), td:nth-child(2) { width: 15%; }
        th:nth-child(3), td:nth-child(3) { width: 18%; }
        th:nth-child(4), td:nth-child(4) { width: 22%; }
        th:nth-child(5), td:nth-child(5) { width: 13%; }
        th:nth-child(6), td:nth-child(6) { width: 10%; }
        th:nth-child(7), td:nth-child(7) { width: 10%; }
        
        h2 { 
          font-size: 14pt; 
          margin: 12px 6px; 
          color: #000;
          font-weight: bold;
        }
        .pdf-container {
          padding: 0;
          color: #000; 
          background-color: #fff;
        }
      </style>
    `;

    const opt = {
      margin: [28, 57, 28, 85], // [top, right, bottom, left] pisteinä
      filename: 'B- ja C-katsomo-varaukset.pdf',
      image: { 
        type: 'jpeg', 
        quality: 1
      },
      html2canvas: { 
        scale: 3,
        useCORS: true,
        letterRendering: true,
        logging: true,
        width: 1170,
        backgroundColor: '#FFF'
      },
      jsPDF: { 
        unit: 'pt',
        format: 'a4',
        orientation: 'landscape',
        compress: true,
        precision: 16
      }
    };

    // Luodaan väliaikainen elementti PDF:ää varten
    const pdfContent = `
      ${pdfStyles}
      <div class="pdf-container" style="color: #000 !important;">
        <h1 class="pdf-title">2024-2025 B- ja C-katsomo</h1>
        ${element.innerHTML.replace(/text-gray-300/g, 'text-black')}
      </div>
    `;

    html2pdf().set(opt).from(pdfContent).save();
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="flex space-x-4 mb-8">
          <button
            onClick={() => fetchADReservations()}
            className="px-4 py-2 bg-gray-700 text-white rounded hover:bg-gray-600 transition-colors"
            disabled={loading}
          >
            {loading ? 'Ladataan...' : 'Näytä A- ja D-katsomon varaukset'}
          </button>
          <button
            onClick={() => fetchBCReservations()}
            className="px-4 py-2 bg-gray-700 text-white rounded hover:bg-gray-600 transition-colors"
            disabled={loading}
          >
            {loading ? 'Ladataan...' : 'Näytä B- ja C-katsomon varaukset'}
          </button>
        </div>

        {/* Näytetään latausanimaatio */}
        {loading && (
          <div className="flex justify-center items-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
          </div>
        )}

        {/* A- ja D-katsomon listaus */}
        {adReservations.length > 0 && (
          <div ref={pdfRef}>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-white">A- ja D-katsomon varaukset</h2>
              <button
                onClick={generateADPDF}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
              >
                Lataa PDF
              </button>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-gray-700 rounded-lg overflow-hidden">
                <thead className="bg-gray-600">
                  <tr>
                    <SortableHeader label="Katsomo" sortKey="section" listType="ad" />
                    <SortableHeader label="Nimi" sortKey="name" listType="ad" />
                    <SortableHeader label="Yritys" sortKey="company" listType="ad" />
                    <SortableHeader label="Sähköposti" sortKey="email" listType="ad" />
                    <SortableHeader label="Varaustyyppi" sortKey="reservation_type" listType="ad" />
                    <SortableHeader label="Kausi" sortKey="season" listType="ad" />
                    <SortableHeader label="Luotu" sortKey="created_at" listType="ad" />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-600">
                  {adReservations.map((reservation) => (
                    <tr key={reservation.id} className="hover:bg-gray-600">
                      <td className="px-4 py-3 text-sm text-gray-300">{reservation.section}</td>
                      <td className="px-4 py-3 text-sm text-gray-300">{reservation.name}</td>
                      <td className="px-4 py-3 text-sm text-gray-300">{reservation.company}</td>
                      <td className="px-4 py-3 text-sm text-gray-300">{reservation.email}</td>
                      <td className="px-4 py-3 text-sm text-gray-300">
                        {getReservationType(reservation.reservation_type)}
                      </td>
                      <td className="px-4 py-3 text-sm text-gray-300">{reservation.season}</td>
                      <td className="px-4 py-3 text-sm text-gray-300">
                        {new Date(reservation.created_at).toLocaleDateString('fi-FI')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* B- ja C-katsomon listaus */}
        {bcReservations.length > 0 && (
          <div ref={pdfRef}>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-white">B- ja C-katsomon varaukset</h2>
              <button
                onClick={generateBCPDF}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
              >
                Lataa PDF
              </button>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-gray-700 rounded-lg overflow-hidden">
                <thead className="bg-gray-600">
                  <tr>
                    <SortableHeader label="Paikka" sortKey="seat_id" listType="bc" />
                    <SortableHeader label="Nimi" sortKey="name" listType="bc" />
                    <SortableHeader label="Yritys" sortKey="company" listType="bc" />
                    <SortableHeader label="Varaustyyppi" sortKey="reservation_type" listType="bc" />
                    <SortableHeader label="Kausi" sortKey="season" listType="bc" />
                    <SortableHeader label="Luotu" sortKey="created_at" listType="bc" />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-600">
                  {bcReservations.map((reservation) => (
                    <tr key={reservation.id} className="hover:bg-gray-600">
                      <td className="px-4 py-3 text-sm text-gray-300">{reservation.seat_id}</td>
                      <td className="px-4 py-3 text-sm text-gray-300">{reservation.name}</td>
                      <td className="px-4 py-3 text-sm text-gray-300">{reservation.company}</td>
                      <td className="px-4 py-3 text-sm text-gray-300">
                        {getReservationType(reservation.reservation_type)}
                      </td>
                      <td className="px-4 py-3 text-sm text-gray-300">{reservation.season}</td>
                      <td className="px-4 py-3 text-sm text-gray-300">
                        {new Date(reservation.created_at).toLocaleDateString('fi-FI')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {adReservations.length === 0 && bcReservations.length === 0 && !loading && (
          <div className="text-gray-300">
            Ei näytettäviä varauksia.
          </div>
        )}
      </div>
    </div>
  );
};

// Lisää PropTypes
Reports.propTypes = {
  session: PropTypes.object
};

export default Reports;