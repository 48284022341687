import PropTypes from 'prop-types';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, reservationDetails }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg max-w-sm w-full mx-4">
        <h2 className="text-xl font-bold text-white mb-4">Vahvista poisto</h2>
        {reservationDetails && (
          <p className="text-gray-400 mb-2 text-sm">
            Paikka: {reservationDetails.seat_id}<br />
            {reservationDetails.name && `Varaaja: ${reservationDetails.name}`}
          </p>
        )}
        <p className="text-gray-300 mb-6">
          Haluatko varmasti poistaa tämän varauksen? Tätä toimintoa ei voi peruuttaa.
        </p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
          >
            Peruuta
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
          >
            Poista
          </button>
        </div>
      </div>
    </div>
  );
};

DeleteConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  reservationDetails: PropTypes.shape({
    seat_id: PropTypes.string,
    name: PropTypes.string
  })
};

export default DeleteConfirmationModal; 