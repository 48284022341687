import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { supabase } from '../lib/supabaseClient';

const ReservationModal = ({ isOpen, onClose, onSubmit, onDelete = null, existingData = null }) => {
//  console.log('Modal existingData:', existingData);

  const [formData, setFormData] = useState({
    name: existingData?.name || '',
    email: existingData?.email || '',
    company: existingData?.company || '',
    mobile: existingData?.mobile || '',
    other_info: existingData?.other_info || '',
    reservationType: existingData?.reservation_type || 'reserved',
    season: '',
    season_id: null
  });

  useEffect(() => {
    const fetchActiveSeason = async () => {
      try {
        const { data, error } = await supabase
          .from('seasons')
          .select('id, name')
          .eq('is_active', true)
          .single();

        if (error) throw error;

        setFormData(prev => ({
          ...prev,
          season: data.name,
          season_id: data.id
        }));
      } catch (err) {
        console.error('Virhe kauden haussa:', err);
      }
    };

    if (isOpen) {
      fetchActiveSeason();
    }
  }, [isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault(); // Estetään lomakkeen oletustoiminta
    
    // Varmistetaan että season_id on mukana
    const dataToSubmit = {
      ...formData,
      season_id: formData.season_id
    };
    
    onSubmit(dataToSubmit);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="bg-gray-800 p-8 rounded-lg max-w-md w-full">
        <h2 className="text-xl font-bold text-white mb-4">
          {existingData 
            ? `Muokkaa varausta - ${existingData.seat_id}`
            : 'Lisää varaus'}
        </h2>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-300 mb-1">Nimi</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:border-blue-500 focus:outline-none"
            />
          </div>
          <div>
            <label className="block text-gray-300 mb-1">Yritys</label>
            <input
              type="text"
              name="company"
              value={formData.company}
              onChange={(e) => setFormData(prev => ({ ...prev, company: e.target.value }))}
              className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:border-blue-500 focus:outline-none"
            />
          </div>
          <div>
            <label className="block text-gray-300 mb-1">Sähköposti</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
              className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:border-blue-500 focus:outline-none"
            />
          </div>
          <div>
            <label className="block text-gray-300 mb-1">Varaustyyppi</label>
            <select
              name="reservationType"
              value={formData.reservationType}
              onChange={(e) => setFormData(prev => ({ ...prev, reservationType: e.target.value }))}
              className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:border-blue-500 focus:outline-none"
            >
              <option value="reserved">Varattu paikka</option>
              <option value="partner">Kumppani</option>
              <option value="guest">Kausikortti Lippu.fi</option>
              <option value="other">Muu</option>
            </select>
          </div>
          <div className="mb-6">
            <label className="block text-gray-300 mb-2">Kausi</label>
            <div className="relative">
              <input
                type="text"
                name="season"
                value={formData.season}
                className="w-full p-2 bg-gray-700 text-white rounded cursor-not-allowed"
                disabled
              />
              <div className="absolute right-3 top-1/2 -translate-y-1/2">
                <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse"></div>
              </div>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Puhelin
            </label>
            <input
              type="tel"
              name="mobile"
              value={formData.mobile}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                mobile: e.target.value
              }))}
              className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Lisätietoja
            </label>
            <textarea
              name="other_info"
              value={formData.other_info}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                other_info: e.target.value
              }))}
              rows="3"
              className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white resize-y"
            />
          </div>
          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-600 text-white text-sm font-medium rounded-md
                hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Peruuta
            </button>
            
            {existingData && (
              <button
                type="button"
                onClick={onDelete}
                className="px-4 py-2 bg-red-600 text-white text-sm font-medium rounded-md
                  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Poista
              </button>
            )}

            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md
                hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {existingData ? 'Tallenna muutokset' : 'Lisää'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

ReservationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  existingData: PropTypes.shape({
    seat_id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
    mobile: PropTypes.string,
    other_info: PropTypes.string,
    reservation_type: PropTypes.string
  })
};

export default ReservationModal;