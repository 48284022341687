import { useState } from 'react';
import PropTypes from 'prop-types';

const MassReservationModal = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    section: 'A',
    season: '2024-2025',
    reservation_type: 'reserved',
    quantity: 1,
    mobile: '',
    other_info: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg max-w-md w-full mx-4">
        <h2 className="text-xl font-bold text-white mb-4">Lisää massavaraus</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Nimi</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value})}
              className="w-full p-2 bg-gray-700 text-white rounded"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Yritys</label>
            <input
              type="text"
              value={formData.company}
              onChange={(e) => setFormData({...formData, company: e.target.value})}
              className="w-full p-2 bg-gray-700 text-white rounded"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Sähköposti</label>
            <input
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({...formData, email: e.target.value})}
              className="w-full p-2 bg-gray-700 text-white rounded"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Katsomo</label>
            <select
              value={formData.section}
              onChange={(e) => setFormData({...formData, section: e.target.value})}
              className="w-full p-2 bg-gray-700 text-white rounded"
              required
            >
              <option value="A">A-katsomo</option>
              <option value="D">D-katsomo</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Kausi</label>
            <select
              value={formData.season}
              onChange={(e) => setFormData({...formData, season: e.target.value})}
              className="w-full p-2 bg-gray-700 text-white rounded"
              required
            >
              <option value="2024-2025">2024-2025</option>
              <option value="2025-2026">2025-2026</option>
              <option value="2026-2027">2026-2027</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Tyyppi</label>
            <select
              value={formData.reservation_type}
              onChange={(e) => setFormData({...formData, reservation_type: e.target.value})}
              className="w-full p-2 bg-gray-700 text-white rounded"
              required
            >
              <option value="reserved">Varattu paikka</option>
              <option value="partner">Kumppani</option>
              <option value="guest">Kausikortti Lippu.fi</option>
              <option value="other">Muu</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Puhelinnumero</label>
            <input
              type="tel"
              value={formData.mobile}
              onChange={(e) => setFormData({...formData, mobile: e.target.value})}
              className="w-full p-2 bg-gray-700 text-white rounded"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Lisätiedot</label>
            <textarea
              value={formData.other_info}
              onChange={(e) => setFormData({...formData, other_info: e.target.value})}
              className="w-full p-2 bg-gray-700 text-white rounded resize-none"
              rows="3"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-300 mb-2">Määrä</label>
            <input
              type="number"
              min="1"
              max="20"
              value={formData.quantity}
              onChange={(e) => setFormData({...formData, quantity: parseInt(e.target.value)})}
              className="w-full p-2 bg-gray-700 text-white rounded"
              required
            />
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
            >
              Peruuta
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Lisää varaukset
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

MassReservationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default MassReservationModal; 